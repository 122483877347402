.ql-editor {
  .mention {
    color: #3182ce;
    font-size: 14px;
    font-weight: bold;
  }
}

.ql-picker-options {
  z-index: 4;
}

.ql-tooltip {
  z-index: 4;
}

.ql-container.ql-disabled {
  border: none;
}

.ql-editor.ql-blank::before {
  font-size: 14px;
  font-style: inherit;
}

.ql-toolbar {
  border-color: #a0aec0 !important;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.ql-container {
  border-color: #a0aec0 !important;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.ql-mention-list-container.ql-mention-list-container-bottom {
  background-color: white;
  z-index: 4;
  border: 1px solid #a0aec0;
  border-radius: 0.375rem;

  .ql-mention-list {
    list-style-type: none;
    max-height: 200px !important;
    overflow-y: auto;

    .ql-mention-list-item {
      padding: 0.5rem;
      cursor: pointer;
      font-family: "Inter", sans-serif !important;
      font-size: 14px;
      &:hover {
        background-color: #f7fafc;
      }
    }
  }
}
