.react-datepicker__header {
  border-bottom: 0px !important;
  background: var(--chakra-colors-gray-100) !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: black !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--chakra-colors-blue-100) !important;
  color: black !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--chakra-colors-blue-500) !important;
  color: white !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  background-color: transparent !important;
  color: black !important;
  box-shadow: inset 0 0 0 1px var(--chakra-colors-blue-500);
  border-radius: 5px;
  font-weight: normal !important;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  height: 48px !important;
  width: 48px !important;
  align-content: center;
}

.react-datepicker__day--range-end {
  background-color: var(--chakra-colors-blue-500) !important;
  color: white !important;
}

.react-datepicker__close-icon {
  padding: 0 33px 0 0 !important;
}

.react-datepicker__today-button {
  border-top: 0px !important;
  background-color: white !important;
  color: var(--chakra-colors-blue-600) !important;
}

.react-datepicker__input-container input::placeholder {
  color: black;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker__header {
  background-color: white !important;
}
