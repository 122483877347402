@font-face {
  font-family: "Heebo";
  src: url("./static/Heebo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Heebo";
  src: url("./static/Heebo-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: bold;
  font-stretch: normal;
}
