.rt-resizable-header-content {
  text-align: center;
}

.dashbutton {
  /*background-color: #cad88a; */
  border: black;
  color: rgb(0, 0, 0);
  padding: 10px 30px;
  text-align: center;
  width: 200px;
  text-decoration: none;
  display: inline-block;
  font-size: 9px;
  margin: 5px 5px;
}

#iconInside {
  display: flex;
  flex-direction: row-reverse;
}

#pageDropDown {
  margin-left: 10px;
  margin-top: 6px;
}

body {
  font-family: "Inter", sans-serif !important;
}

.btn {
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
}

.react-bootstrap-table-pagination-list {
  margin-top: 11px;
  margin-left: -5px;
}

body {
  font-size: 1em;
  letter-spacing: -0.006em !important;
  line-height: 20px !important;
}

.popover {
  font-size: 1em;
  letter-spacing: -0.006em !important;
  line-height: 20px !important;
}

th {
  font-size: 1em;
  letter-spacing: -0.006em !important;
  line-height: 20px !important;
}

input {
  font-size: 1em;
  letter-spacing: -0.006em !important;
  line-height: 20px !important;
}

/* hovering over each individual role */
.table-hover > tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.worksheet-exclamation-circle {
  float: left;
  padding-top: 11px;
  padding-left: 11px;
  color: rgb(226, 130, 119);
  cursor: default;
  margin-right: 8px;
  margin-top: 2px;
}

.worksheet-exclamation-circle-none {
  float: left;
  padding-top: 11px;
  padding-left: 11px;
  color: rgba(226, 130, 119, 0);
  cursor: default;
  margin-right: 8px;
  margin-top: 2px;
}

.worksheet-exclamation {
  color: #ff0000;
  display: inline;
  margin-right: 16px;
  margin-left: -21px;
  cursor: default;
  z-index: 2;
  position: relative;
  pointer-events: none;
}

.mortgage-table-button-focus:focus {
  background-color: transparent !important;
  color: rgb(102, 97, 91) !important;
}

.grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/*
.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
} */
.grabbable2 {
  cursor: grabbing !important;
  z-index: 9999;
  cursor: -moz-grabbing;
  background-color: blue;
}

.react-select-bold {
  font-weight: 600;
}

a.mortgage-table-button-focus:focus {
  background-color: transparent !important;
  color: rgb(102, 97, 91) !important;
  outline: -webkit-focus-ring-color auto 1px !important;
}

button.mortgage-table-button-focus:focus {
  background-color: transparent !important;
  color: rgb(102, 97, 91) !important;
  outline: -webkit-focus-ring-color auto 1px !important;
}

.mortgage-table-header {
  position: sticky;
  z-index: 10;
  top: 0;
  padding-bottom: 5px;
}

.filter-label {
  width: 100%;
  display: contents;
}

.react-bootstrap-table-page-btns-ul {
  font-weight: bold;
}

.react-bootstrap-table-pagination-total {
  font-weight: bold;
}

.navbar-brand {
  margin-left: 10px;
}

.navbar {
  position: sticky !important;
  top: 0 !important;
}

.react-select
  .react-select__control
  .react-select__indicators
  .react-select__dropdown-indicator:after {
  margin-bottom: -3px !important;
}

.react-select-padding {
  padding-right: 10px !important;
}

.react-select__placeholder {
  font-weight: 500;
  font-size: 1em !important;
}

.mortgage-table-border {
  overflow: scroll;
  border-bottom: 1px solid #dee2e6;
}

.react-select__indicator {
  margin-bottom: 1px;
}

.react-select__multi-value {
  border: #5a4646 solid 1px;
  border-radius: 5px !important;
}

.table {
  margin-bottom: 0;
}

.__react_component_tooltip {
  text-transform: capitalize;
}

.mortgage-table-expand-row {
  border: 1px solid black !important;
  background-color: rgba(0, 142, 255, 0.2) !important;
  width: 35px !important;
  padding-bottom: 15px !important;
}

.expand-cell-header {
  background-color: #e3e3e3;
  cursor: pointer;
  width: 35px !important;
  vertical-align: middle !important;
}

.expand-cell {
  cursor: pointer;
  vertical-align: middle !important;
}

.btn.btn-secondary {
  font-weight: 600 !important;
  font-size: 0.8571em !important;
  line-height: 1.35em !important;
  text-transform: uppercase;
  border: none;
  margin: 10px 1px;
  border-radius: 3px;
  padding: 11px 22px;
  cursor: pointer;
  background-color: #66615b;
  color: #fff;
  transition: all 0.15s linear;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 !important;
  transition: box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
}

.modal-content {
  border: 0;
  border-radius: 10px !important;
  box-shadow: 0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%);
}

.btn-primary {
  background-color: #51cbce !important;
  border: 0 solid white !important;
  color: #fff;
}

.btn-success {
  background-color: #6bd098 !important;
  border: 0 solid white !important;
  color: #fff;
}

.page-item .disabled {
  color: rgb(37, 36, 34);
}

.pagination .page-item.active > .page-link,
.pagination .page-item.active > .page-link:focus,
.pagination .page-item.active > .page-link:hover {
  background-color: #51cbce;
  border-color: #51cbce;
  color: #fff;
}

.pagination .page-item .page-link {
  border: 0;
  border-radius: 30px !important;
  transition: all 0.3s;
  padding: 0 11px;
  margin: 0 3px;
  min-width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #2c2c2c;
  cursor: pointer;
  font-size: 1em;
  text-transform: uppercase;
  background: transparent;
  outline: none;
}

.bg-success {
  /*background: linear-gradient(203deg, rgba(201,235,187,1) 0%, rgba(107,208,152,1) 50%, rgba(201,235,187,1) 100%) !important;

     background-image: linear-gradient(203deg, rgba(201,235,187,1) 0%, rgb(11, 160, 57) 50%, rgba(201,235,187,1) 100%) !important;
    background-size: auto 200%;
    background-position: 0 100%;
    transition: background-position 0.5s;
     */
}

.bg-success2 {
  /*background-image: linear-gradient(#5187c4, #1c2f45);
    background-image: linear-gradient(203deg, rgba(201,235,187,1) 0%, rgb(11, 160, 57) 50%, rgba(201,235,187,1) 100%) !important;
    background-size: auto 200%;
    background-position: 0 100%;
    transition: background-position 0.5s;

     */
}

.bg-success2:hover {
  background-position: 0 0 !important;
  /*background: rgba(107,208,152,1) !important; */
}

.bg-normal2 {
  /*background: linear-gradient(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,0.85) 50%, rgb(103, 97, 79) 100%) !important;
    */
  background-image: linear-gradient(#5187c4, #1c2f45);
  background-size: auto 200%;
  background-position: 0 100%;
  transition: background-position 0.5s;
}

.bg-normal2:hover {
  /*
    background: linear-gradient(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,1) 50%, rgb(103, 97, 79) 100%) !important;
    */
  background-position: 0 0;
}

.bg-category {
  /*background: linear-gradient(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,0.85) 50%, rgb(103, 97, 79) 100%) !important;
    */
  background-image: linear-gradient(
    0deg,
    rgba(36, 133, 91, 1) 0%,
    rgba(66, 207, 153, 1) 100%
  ); /*Color of the button*/
  background-size: auto 200%;
  background-position: 0 100%;
  transition: background-position 0.5s;
}

.bg-category:hover {
  /*
    background: linear-gradient(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,1) 50%, rgb(103, 97, 79) 100%) !important;
    */
  background-position: 0 0;
}

.bg-mustard {
  /*background: linear-gradient(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,0.85) 50%, rgb(103, 97, 79) 100%) !important;
    */
  background-image: linear-gradient(
    0deg,
    rgb(248, 240, 101) 0%,
    rgb(211, 181, 47) 100%
  ); /*Color of the button*/
  background-size: auto 200%;
  background-position: 0 100%;
  transition: background-position 0.5s;
}

.bg-mustard:hover {
  /*
    background: linear-gradient(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,1) 50%, rgb(103, 97, 79) 100%) !important;
    */
  background-position: 0 0;
  color: black !important;
}

.btn-warning {
  color: black !important;
}

.btn-warning:hover {
  color: black !important;
}

.bg-white {
  /*background: linear-gradient(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,0.85) 50%, rgb(103, 97, 79) 100%) !important;
    */
  background-image: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgb(169, 166, 166) 100%
  ) !important; /*Color of the button*/
  background-size: auto 200%;
  background-position: 0 100%;
  transition: background-position 0.5s;
  font-weight: 700 !important;
}

.bg-white:hover {
  /*
    background: linear-gradient(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,1) 50%, rgb(103, 97, 79) 100%) !important;
    */
  background-position: 0 0;
}

.bg-bad {
  background-image: linear-gradient(
    0deg,
    rgba(133, 36, 36, 1) 0%,
    rgba(207, 66, 66, 1) 100%
  );
  background-size: auto 200%;
  background-position: 0 100%;
  transition: background-position 0.5s;
}

.bg-bad:hover {
  background-position: 0 0;
}

.bg-success3 {
  background: linear-gradient(
    203deg,
    rgba(144, 245, 189, 1) 0%,
    rgba(107, 208, 152, 1) 50%,
    rgba(144, 245, 189, 1) 100%
  ) !important;
  background: rgba(107, 208, 152, 1) !important;
}

.bg-success3:hover {
  background: linear-gradient(
    203deg,
    rgba(86, 213, 143, 1) 0%,
    rgba(107, 208, 152, 1) 50%,
    rgba(86, 213, 143, 1) 100%
  ) !important;
  background: rgba(107, 208, 152, 1) !important;
}

td {
  vertical-align: middle !important;
}

.form-control {
  height: 42px !important;
  font-size: 1em !important;
}
